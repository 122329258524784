@import "src/utils/spacing";

.main {
  cursor: default;

  overflow: initial;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: var(--theme-text-primary);
  text-align: center;
}

.contentProgress {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 156px;
  margin-bottom: --spaces(5);

  background-color: #22262d;
  border-radius: 4px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: --spaces(5);
  align-items: center;
  justify-content: center;

  width: 100%;

  @media (min-width: 960px) {
    width: 70%;
  }

  @media (min-width: 1440px) {
    gap: --spaces(20);
    width: 60%;
  }
}
