@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.infoCard {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: --spaces(5);

  color: var(--theme-white);

  background-color: var(--theme-dark-background);
  border-radius: 4px;
}

.aside {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  margin-right: --spaces(2);
}

.description {
  display: flex;
  flex-direction: column;
  gap: --spaces(5);
  align-items: flex-start;
  justify-content: center;
}

.icon {
  width: 28px;
  height: 28px;
}

.cardName {
  text-align: start;
}

.total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  margin-right: --spaces(2);
  padding: --spaces(2);
}
