@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.title {
  margin: 0;
  font-size: 30px;
}

.description {
  font-size: 20px;
}

.icon {
  width: 300px;
  height: 300px;
}
