@import "src/utils/spacing";

.captionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  margin: --spaces(4) 0;
}

.noCaptions {
  font-size: 0.8rem;
}

.captionsList {
  width: 100%;
}

.captionsList li {
  padding-left: --spaces(4);
  border-radius: 4px;

  &:hover {
    background-color: rgb(255 255 255 / 10%);
  }
}

.captionsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
