@import "src/utils/spacing";

.header {
  display: flex;
  flex-direction: row;
  gap: --spaces(4);
  align-items: center;

  /* stylelint-disable selector-class-pattern */
  :global {
    .MuiIconButton-root:hover {
      background-color: var(--theme-primary);
    }

    .MuiSvgIcon-root {
      font-size: inherit;
    }
  }
  /* stylelint-enable selector-class-pattern */
}

.iconButton {
  width: 30px;
  height: 30px;
  background: var(--theme-primary);
}

.divider {
  width: 1px;
  height: 44px;
  background-color: var(--theme-divider);
}

.header h1 {
  cursor: default;
}
