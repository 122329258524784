@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(1);
  justify-content: space-between;

  width: 100%;
}

.item {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(4);

  @media (min-width: breakpoints.$breakpoint-md) {
    flex-direction: column;
    width: 49%;
  }
}

.soloItem {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(4);
}

.datePickItem {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(3);

  @media (min-width: breakpoints.$breakpoint-md) {
    flex-direction: column;
    width: 49%;
  }
}

.datePick {
  width: 100% !important;

  @media (max-width: breakpoints.$breakpoint-md) {
    flex-direction: column;
    width: 49% !important;
  }
}

/* stylelint-disable selector-class-pattern */
:global {
  .react-datepicker-wrapper {
    width: 100% !important;
  }

  .react-datepicker__input-container {
    div {
      width: 100% !important;
    }
  }
}

/* stylelint-enable selector-class-pattern */
.attachmentButton {
  color: #dda430;
  border: 1px solid #dda430;

  a {
    padding: 1rem;
    color: #dda430;
    text-decoration: none;
  }
}

.uploadContent {
  cursor: pointer;

  display: flex;

  width: 100%;
  height: 56px;
  padding: --spaces(3);

  color: currentcolor;

  border: 1px solid currentcolor;
  border-radius: 4px;
}

.uploadSurface {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.uploadClick {
  display: flex;
  align-items: center;
}

.uploadIcon {
  width: 26px;
  height: 26px;
  margin-right: --spaces(2);
}

.warningContainer {
  display: flex;
  gap: --spaces(2);
  align-items: center;
  justify-content: center;

  width: 100%;
  margin: --spaces(3) auto --spaces(4.5);
}

.warningContainer .iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: 25px;
  padding: --spaces(0.5);

  border: 2px solid #eb1111;
  border-radius: 50px;
}

.warningContainer span {
  line-height: 18px;
}

.error {
  padding-left: --spaces(3);
}

.submitButton {
  width: 95%;
  max-width: 316px;
  height: 30px;
  margin: --spaces(5) auto 0;
}

.download {
  width: 100%;
  height: 100%;

  color: #634bde;
  text-transform: none;

  border: 1px solid #634bde;
}

.uploadContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.uploadWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.uploadButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.errorMessage {
  margin-top: --spaces(0.75);
  margin-left: --spaces(3);

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #f44336;
}

.pickError {
  /* stylelint-disable selector-class-pattern */
  :global {
    .MuiFormLabel-root.Mui-focused {
      color: #f44336;
    }

    .MuiOutlinedInput-root fieldset {
      border-color: #f44336 !important;
    }
  }

  /* stylelint-enable selector-class-pattern */
  label {
    color: #f44336;
  }
}

.contractContainer {
  display: flex;
  flex-direction: row !important;
  gap: --spaces(2);
}
