@import "src/utils/spacing";

.container {
  scrollbar-color: var(--theme-primary) rgb(255 255 255 / 10%);
  scrollbar-width: thin;

  overflow-y: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: --spaces(4);

  width: 100%;
  padding-top: --spaces(4);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(255 255 255 / 10%);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--theme-primary);
    border-radius: 6px;
  }
}

.loadingContainer {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 120px;
  padding-top: --spaces(4);
}

.noContentContainer {
  display: flex;
  flex-direction: column;
  gap: --spaces(20);
  width: 100%;
}

.titleContainer {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.dataErrorContainer {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
  align-items: center;
}

.dataError {
  width: 200px;
  height: 200px;
}

.dataErrorText {
  max-width: 500px;
  text-align: center;
}
