@import "src/utils/spacing";

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: --spaces(3);

  background-color: rgb(255 255 255 / 10%);
  border-radius: 4px;
}

.videoPreviewContainer {
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 400px;
}

.changeVideoButton {
  position: absolute;
  top: 0;
  right: --spaces(-10);

  width: 30px;
  height: 30px;
  padding: --spaces(1);
}

.videoPreview {
  aspect-ratio: 16/9;
  width: 100%;
  max-width: 400px;
}

.videoLabel {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  aspect-ratio: 16/9;
  width: 100%;
  max-width: 400px;

  text-align: center;

  border: 1px dashed var(--theme-divider);
  border-radius: 4px;

  &:hover {
    color: var(--theme-primary);
    border: 1px dashed var(--theme-primary);

    svg,
    video {
      border: 0;
    }
  }
}

.videoInput {
  display: none;
}

.captionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  margin: --spaces(4) 0;
  padding-left: --spaces(4);
}

.noCaptions {
  font-size: 0.8rem;
}

.captionsList {
  width: 100%;
}

.captionsList li {
  padding-left: --spaces(4);
  border-radius: 4px;

  &:hover {
    background-color: rgb(255 255 255 / 10%);
  }
}

.captionsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
