@use "src/utils/breakpoints" as breakpoints;

.modalContainer {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background: rgb(0 0 0 / 60%);
  /* stylelint-disable selector-class-pattern */
  & :global .MuiInputBase-root input {
    box-shadow: 0 0 0 100px #22262d inset;
  }
  /* stylelint-enable selector-class-pattern */
}

.modalSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  min-width: 300px;
  max-width: 80vw;
  max-height: 90%;
  padding: 15px;

  background-color: #22262d;
  border-radius: 5px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);

  & :global {
    *::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    *::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 5px;
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    }
  }
}

.header {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 10px 5px;

  .text {
    display: flex;
    flex-direction: column;
    margin: 5px 0 0;
    font-weight: 600;

    .title {
      cursor: default;
      font-size: 20px;
      color: var(--theme-text-primary);

      @media (max-width: 425px) {
        font-size: 15px;
      }
    }

    .subTitle {
      cursor: default;

      margin: 10px 0;

      font-size: 11px;
      color: var(--theme-text-highlight);
      text-transform: uppercase;

      @media (max-width: 425px) {
        font-size: 9px;
      }
    }
  }

  button {
    position: absolute;
    right: 5px;

    padding: 2px;

    background-color: var(--theme-surface);
    border: none;
    outline: none;

    svg path {
      fill: var(--theme-primary);
    }
  }
}

.header span {
  overflow: hidden;

  font-size: 20px;
  font-weight: bold;
  color: var(--theme-text-highlight);
  text-align: center;
  letter-spacing: 0.06px;
}

.content {
  overflow-y: auto;
  flex: 1 1 auto;

  width: 100%;
  margin-top: 26px;
  padding: 5px;

  font-size: 16px;
  font-weight: 600;
  text-align: justify;
  letter-spacing: 0.03px;
}

@media (max-width: 1045px) {
  .section {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .section {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .header button {
    top: 5px;
    right: 5px;
    padding: 8px;
  }

  .header span {
    font-size: 16px;
  }
}
