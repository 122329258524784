@import "src/utils/spacing";

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: --spaces(5);
}

.titleContainer {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;
}

.selectFileContainer {
  display: flex;
  gap: --spaces(3);
  align-items: center;
  margin-bottom: --spaces(8);
}

.addAttachmentButton {
  min-width: 180px;
  font-size: 12px;
}

.attachmentsList {
  scrollbar-color: var(--theme-primary) rgb(255 255 255 / 10%);
  scrollbar-width: thin;

  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: --spaces(2);

  width: 100%;
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(255 255 255 / 10%);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--theme-primary);
    border-radius: 6px;
  }
}

.attachmentsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: --spaces(2);

  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.attachmentDetails {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  width: 100%;
}

.attachmentIcon {
  width: 40px;
  height: 40px;
}

.attachmentInput {
  display: none;
}
