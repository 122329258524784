@import "src/utils/spacing";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: --spaces(12.5);
  align-items: center;
  justify-content: flex-start;

  width: 100%;
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(5);
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.main {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
  align-items: center;
  justify-content: center;

  width: 100%;
}
