@import "src/utils/spacing";

.actions {
  display: flex;
  justify-content: flex-end;
}

.chipExpired {
  color: #ff8b8b;
  border-color: #ff8b8b;
}

.chipPaid {
  color: #90ff96;
  border-color: #90ff96;
}

.chipPending {
  color: #f7b888;
  border-color: #f7b888;
}

.table td {
  display: flex;
  justify-content: flex-end;
}

.seeBoletoLink {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  color: inherit;
}
