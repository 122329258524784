@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(1);
  justify-content: space-between;

  width: 100%;
  margin-bottom: --spaces(2);
}

.item {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(2);

  div {
    width: 100% !important;
  }

  @media (min-width: breakpoints.$breakpoint-md) {
    width: 49%;
  }
}

.tooltip {
  display: flex;
  align-items: center;

  padding: --spaces(3);

  background: #22262d;
  border-radius: 4px;
}
