@import "src/utils/spacing";

.container {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: --spaces(3);
  align-items: center;
  justify-content: flex-start;

  width: 120px;
  height: 150px;
  padding: --spaces(2);
  padding-top: --spaces(4);

  background-color: rgb(255 255 255 / 10%);
  border-radius: 4px;

  &:hover {
    border: 1px solid var(--theme-primary);
  }

  @media screen and (min-width: 1200px) {
    width: 170px;
    height: 210px;
    padding-top: --spaces(8);
  }
}

.icon {
  height: 60px;

  @media screen and (min-width: 1200px) {
    height: 80px;
  }
}

.label {
  font-size: 14px;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 18px;
  }
}
