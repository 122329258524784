@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.main {
  cursor: default;

  overflow: initial;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: --spaces(6);
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: var(--theme-text-primary);
  text-align: center;
}
