@import "src/utils/spacing";

.modalContent {
  display: flex;
  flex-direction: column;
  gap: --spaces(6);
  align-items: flex-start;
  justify-content: flex-start;
}

.modalInputGroup {
  display: flex;
  flex-direction: column;
  gap: --spaces(1);
  width: 100%;
}

.captionDropzone {
  cursor: pointer;

  display: flex;
  gap: --spaces(2);
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100px;

  border: 1px dashed var(--theme-divider);

  &:hover {
    color: var(--theme-primary);
    border-color: var(--theme-primary);
  }
}
