@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.containerUrl {
  display: flex;
  flex-direction: column;

  a {
    padding: 1rem;
    color: #c5c5cb;
    text-decoration: none;
  }
}

.usersContainer {
  display: flex;
  gap: 15px;
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(3);
  align-items: center;
}
