@import "src/utils/spacing";

.form {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: --spaces(5);
}

.titleContainer {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;
}

.content {
  cursor: default;

  width: 100%;
  min-height: 200px;
  padding: --spaces(3);

  color: rgb(255 255 255 / 50%);

  border: 1px solid rgb(255 255 255 / 30%);
  border-radius: 4px;

  a {
    color: var(--theme-primary);
  }
}
