@import "src/utils/spacing";

.appDrawer {
  flex-shrink: 0;
  box-sizing: border-box;
  white-space: nowrap;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  /* stylelint-disable selector-class-pattern */
  & :global .MuiDrawer-paper {
    z-index: 1;
    width: inherit;
  }

  .appDrawerContent {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    & :global .MuiListItem-root {
      display: flex;
      justify-content: center;
    }
  }
  /* stylelint-enable selector-class-pattern */
}

.drawerHeader {
  display: flex;
  justify-content: flex-end;
  padding-top: --spaces(19);
  padding-right: --spaces(4);
}

.customDrawer {
  img {
    height: 40px;
  }
}

.customListItemButton {
  display: flex;
  justify-content: center;
}

.customListItemIcon {
  /* stylelint-disable selector-class-pattern */
  &:global.MuiListItemIcon-root {
    min-width: 0;
  }
  /* stylelint-enable selector-class-pattern */
}

.close {
  overflow-x: hidden;
  width: 76px;
}

.open {
  width: 250px;
}

.customListItemText {
  margin-left: --spaces(2);
}

.logoutButton {
  cursor: pointer;
  padding-bottom: --spaces(4);
}

.content {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding: 20px;
}
