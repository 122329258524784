@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.main {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
  margin-top: --spaces(4);
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(2);
  align-items: flex-end;

  width: 100%;
}

.accountFilter {
  width: 40%;
}

.statusFilter,
.dateFilter {
  flex: 1;
}
