@import "src/utils/spacing";

.menuBarContainer {
  display: flex;
  flex-wrap: wrap;
  padding: --spaces(1) 0;
  background-color: var(--theme-text-secondary);
}

.editorContainer {
  width: 100%;
}

.container {

  /* stylelint-disable selector-class-pattern */
  :global {
    .ProseMirror p,
    .ProseMirror span {
      font-size: 14px;
      font-weight: 400;
      color: var(--theme-text-primary) !important;
    }

    .ProseMirror a,
    .ProseMirror a span {
      font-size: 14px;
      font-weight: 600;
      color: var(--theme-primary) !important;
    }
  }

  /* stylelint-enable selector-class-pattern */
}

.disabled {
  color: var(--theme-gray);

  div div {
    border: 1px solid var(--theme-gray);
  }
}
