@import "src/utils/spacing";

.content {
  display: flex;
  justify-content: space-between;
}

.error {
  width: 100%;

  div {
    border: 1px solid  var(--theme-error);
    border-radius: 5px;
  }
}

.helperText {
  padding-left: --spaces(4);
}
