@import "src/utils/spacing";

.modalContent {
  display: flex;
  flex-direction: column;
  gap: --spaces(6);
  align-items: flex-start;
  justify-content: flex-start;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cancelButton,
.confirmButton {
  width: 45%;
}
