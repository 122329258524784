@import "src/utils/spacing";

.container {
  /* stylelint-disable selector-class-pattern */
  :global {
    .category-tags-field_box__iD5vh {
      text-transform: none;
    }

    .MuiOutlinedInput-root {
      padding-right: --spaces(6) !important;
    }
  }
}
/* stylelint-enable selector-class-pattern */

.box {
  display: flex;
  gap: --spaces(1.25);
  align-items: center;
  text-transform: capitalize;
}
