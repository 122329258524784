@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.form {
  padding-bottom: 3rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.item {
  width: 100%;
  margin-bottom: --spaces(4);

  @media (min-width: breakpoints.$breakpoint-md) {
    width: 49%;
  }
}

.soloItem {
  width: 100%;
  margin-bottom: --spaces(2);
}

.tooltip {
  cursor: pointer;
}

.buttonsContainer {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: --spaces(10);

  & > button {
    width: 50%;
  }
}
