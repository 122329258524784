@import "../authentication.module";

.resendCode {
  cursor: pointer;

  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.38px;
}
