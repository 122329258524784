@import "src/utils/spacing";

.buttons,
.date {
  display: flex;
  flex-direction: row;
  gap: --spaces(3);
}

.date {
  margin-top: --spaces(1);
}
