@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(1);
  justify-content: space-between;

  width: 100%;
  margin-bottom: --spaces(3);
}

.soloItem {
  width: 100%;
  margin-bottom: --spaces(3);
}

.item {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(3);

  div {
    width: 100% !important;
  }

  @media (min-width: breakpoints.$breakpoint-md) {
    width: 49%;
  }
}
