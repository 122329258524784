@import "src/utils/spacing";

.cropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: --spaces(4);

  background-color: #1e1e1e;
  border-radius: 5px;

  :global {

    /* stylelint-disable selector-class-pattern */
    .MuiSlider-root {
      width: 80%;
      margin-bottom: --spaces(1.5);
      color: var(--theme-surface);
    }

    .MuiSlider-thumb {
      color: var(--theme-new-primary);
      border: 2px solid var(--theme-surface);
    }

    /* stylelint-enable selector-class-pattern */
  }
}

.cropper {
  position: relative;
  aspect-ratio: 16/9;
  width: 100%;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.cropButtons {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: --spaces(4);
}

.rotateBtn {
  margin-right: --spaces(3);
  color: #fff;
  background-color: rgb(217 217 217 / 30%);
}
