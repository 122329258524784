@import "src/utils/spacing";

.container,
.form {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
  width: 100%;
}

.sectionContent {
  display: flex;
  gap: --spaces(3);
}

.section {
  display: flex;
  gap: --spaces(4);
  align-items: flex-end;
  justify-content: space-between;
}
