@import "src/utils/spacing";

.header {
  width: 100%;
  margin: 1rem 0;
}

.content {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
}

.accontSelect {
  width: 100%;
}
