@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.content {
  width: 100%;
  min-height: 70%;
  padding-top: --spaces(9);
  padding-left: --spaces(9);

  background-color: var(--theme-mobile-bg);
  border-radius: 5px;
  box-shadow: var(--theme-box-shadow-card-pendency);

  @media (min-width: breakpoints.$breakpoint-md) {
    width: 70%;
  }

  @media (min-width: 1440px) {
    width: 55%;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: --spaces(5.5);
  margin-right: --spaces(9);
}

.containerSummary {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
  align-items: center;
}

.icon {
  width: 32px;
  height: 32px;
  margin-right: --spaces(1.25);
}

.amount {
  font-size: 22px;
  font-weight: 700;
  color: var(--theme-text-primary);
}

.text {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-text-primary);
}

.contentSummary {
  display: flex;
  flex-direction: row;
  gap: --spaces(2);
  align-items: center;

  width: 100%;
  height: 40px;
}

.logDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: --spaces(3);
  margin-bottom: --spaces(2);
}

.header {
  display: grid;
  grid-template-columns: 3fr repeat(2, 1fr);

  margin-right: --spaces(3);

  font-size: 12px;
  font-weight: 500;
  color: var(--theme-text-secondary);
}

.detail {
  display: grid;
  grid-template-columns: 3fr repeat(2, 1fr);
  align-items: center;

  min-height: 26px;

  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: var(--theme-text-primary);

  &:hover {
    background-color: var(--theme-selected-space);
    border-radius: 5px;
  }
}

.divider {
  height: 1px;
  background-color: var(--theme-text-secondary);
}

.logDetailsMain {
  scrollbar-color: var(--theme-primary) rgb(255 255 255 / 10%);
  scrollbar-width: thin;

  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: --spaces(8);

  max-height: 240px;
  padding-top: --spaces(2);

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(255 255 255 / 10%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--theme-primary);
  }

  @media (min-width: breakpoints.$breakpoint-sm) {
    gap: --spaces(3);
  }

  @media (min-width: 1080px) {
    gap: --spaces(0.5);
  }
}

.headerTitle,
.description {
  padding-left: --spaces(1);
}

.description {
  margin-right: --spaces(2);
}

.button {
  width: 330px;
  margin-bottom: --spaces(4);
}

.button svg {
  position: relative;
  left: 0;
  margin-right: --spaces(2);
}
