@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.buttons,
.date {
  display: flex;
  flex-direction: row;
  gap: --spaces(3);
}

.date {
  margin-top: --spaces(1);
}

.mediasContainer {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: --spaces(4);
  padding: 0;
  padding-left: --spaces(4);

  @media screen and (min-width: breakpoints.$breakpoint-md) {
    flex-direction: row;
    min-height: 255px;
  }
}
