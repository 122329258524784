@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: --spaces(12);
  align-items: center;
  justify-content: flex-start;

  width: 100%;
}

.generalDashboardHeader {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(5);
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.main {
  margin-top: --spaces(22);
}

.gridContainer {
  display: grid;
  grid-gap: --spaces(2);
  grid-template-columns: 1fr;

  width: 100%;
  margin-bottom: --spaces(5);

  @media (min-width: breakpoints.$breakpoint-sm) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.tabContainer {
  margin-bottom: --spaces(5);
}
