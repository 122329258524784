@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
  gap: --spaces(2);
  align-items: flex-end;

  height: 100%;
  margin-bottom: --spaces(5);
}

.paginated {
  height: fit-content;
}

.tableContainer {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding-top: 5px;

  table {
    position: relative;

    overflow: hidden;
    border-spacing: 0;

    width: 100%;

    background-color: #2d3136;
    background-image: linear-gradient(rgb(255 255 255 / 5%), rgb(255 255 255 / 5%));
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 20%) 0 2px 1px -1px, rgb(0 0 0 / 14%) 0 1px 1px 0, rgb(0 0 0 / 12%) 0 1px 3px 0;

    tr {
      height: 46px;
    }

    thead {
      position: sticky;
      z-index: var(--theme-thead-z-index);
      top: -6px;

      tr {
        height: 46px;
      }
    }

    th {
      margin: 0;
      padding: 5px 20px;

      font-size: clamp(14px, 0.9vw, 16px);
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      color: var(--theme-text-primary);
      text-align: left;
      letter-spacing: 0.21px;
      vertical-align: middle;
    }

    td {
      overflow: hidden;

      max-width: 20vw;
      padding: 5px 20px;

      font-weight: 400 !important;
      text-overflow: ellipsis;
      vertical-align: middle;

      border-top: solid 1px rgb(255 255 255 / 10%);
    }

    tbody {
      tr {
        position: relative;
        height: 50px;
      }
    }

    tbody > tr {
      &:hover {
        background-color: #33383e;
      }
    }

    .clickableRow {
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}

.actionsCell {
  text-align: right !important;
}

.cellValue {
  font-size: clamp(12px, 0.8vw, 14px);
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: var(--theme-text-primary);
  text-align: left;
  letter-spacing: 0.18px;
}

.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  margin-top: 20px;

  img {
    width: 280px;

    @media (max-width: breakpoints.$breakpoint-md) {
      width: 280px;
    }

    @media (max-width: breakpoints.$breakpoint-sm) {
      width: 120px;
    }
  }

  p {
    margin-top: 20px;

    font-size: 18px;
    font-weight: bold;
    color: var(--theme-text-primary);
    text-align: center;
  }
}

button {
  cursor: pointer;
}
