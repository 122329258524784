@use "src/utils/breakpoints" as breakpoints;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.filters {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;

  & > div {
    min-width: 130px;
  }

  @media (max-width: breakpoints.$breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

.chartContainer {
  flex-grow: 1;
  width: 100%;
}
