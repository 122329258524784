@import "src/utils/spacing";

.modalContainer {
  position: relative;
  top: --spaces(10);
  left: 50%;
  transform: translateX(-50%);

  width: 480px;
}

.uploadContainer {
  display: flex;
  flex-grow: 1;
  margin-bottom: --spaces(10);
}

.dropzone {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  width: 100%;
  padding: --spaces(4);

  background-color: rgb(0 0 0 / 12%);
  border: solid 1px rgb(255 255 255 / 10%);
  border-radius: 12px;

  :global {

    /* stylelint-disable selector-class-pattern */
    .MuiAvatar-root {
      width: 88px;
      height: 88px;
    }

    /* stylelint-enable selector-class-pattern */
  }
}

.dropzone > .dropText {
  display: block;

  max-width: 240px;
  margin-top: --spaces(5.5);
  margin-left: --spaces(6);

  text-align: left;
}

.cropContainer {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 480px;

  border-radius: 5px;

  :global {

    /* stylelint-disable selector-class-pattern */
    .MuiSlider-root {
      width: 80%;
      margin-bottom: --spaces(1.5);
      color: var(--theme-surface);
    }

    .MuiSlider-thumb {
      color: var(--theme-new-primary);
      border: 2px solid var(--theme-surface);
    }

    /* stylelint-enable selector-class-pattern */
  }
}

.cropButtons {
  bottom: --spaces(3);

  display: flex;
  flex-direction: row-reverse;

  width: 100%;
  margin-top: --spaces(4);
  padding-right: --spaces(4);
  padding-bottom: --spaces(3);
}

.rotateBtn {
  margin-right: --spaces(3);
  color: white;
  background-color: rgb(217 217 217 / 30%);
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modalContainer .header {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 50px;

  font-size: 16px;
  color: #59575b;
}

.dropzone span {
  width: 404px;
  margin-top: --spaces(8);

  font-size: 16px;
  color: #59575b;
  text-align: center;
}

.cropContainer .header {
  padding: --spaces(0) --spaces(4);
  color: white;
  background-color: #1e1e1e;
}

.cropContainer .cropper {
  position: relative;
  flex-grow: 1;
  width: 100%;
}

.cropContainer .controls {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  padding: --spaces(4) 0;

  background-color: #1e1e1e;
}

.buttons button {
  width: 48%;
}

.modalContainer .header .text {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.cropContainer .header .text {
  z-index: 1;
  font-weight: bold;
  color: var(--theme-surface);
}

.cropContainer .header button {
  z-index: 1;
  color: white;
  background: #1e1e1e;
  border: none;
}

.cropContainer .header button svg path {
  stroke: white;
}

.cropContainer .header button svg g path {
  fill: var(--theme-surface);
}

.cropContainer .header button:first-child svg path {
  fill: #fff;
}

.cropContainer .header button svg circle {
  fill: #000;
}
