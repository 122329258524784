@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: --spaces(6);
  align-items: center;

  width: 100%;
  height: 360px;

  border-radius: 5px;
  box-shadow: var(--theme-box-shadow-card-pendency);

  @media (min-width: breakpoints.$breakpoint-sm) {
    flex-direction: row;
    gap: --spaces(20);
  }

  @media (min-width: breakpoints.$breakpoint-md) {
    width: 70%;
  }

  @media (min-width: 1440px) {
    width: 55%;
  }
}

.image {
  width: 340px;
}

.divider {
  height: 1px;
  background-color: var(--theme-text-secondary);
}

.ready {
  font-size: 22px;
  font-weight: 700;
  color: var(--theme-text-primary);
}

.importedSuccessfully {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-text-primary);
}

.information {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: --spaces(7);
}

.check {
  display: flex;
  gap: --spaces(3);
  align-items: center;
}

.icon {
  width: 32px;
  height: 32px;
}

.total {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: var(--theme-text-primary);
}

.button {
  width: 100%;
}
