@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px - 64px);
}

.main {
  overflow: hidden;
  display: grid;
  grid-template-columns: 300px auto;
  grid-template-rows: 100%;
  flex-grow: 1;
  gap: --spaces(2);

  width: 100%;

  transition: ease-in-out 0.4s;

  &.newTopic {
    grid-template-columns: 0 auto;
  }
}

.topicsContainer {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: --spaces(3);

  padding: --spaces(2);

  background-color: #2d3136;
  border-radius: 8px;

  transition: ease-in-out 0.4s;

  &.newTopic {
    padding: 0;
  }
}

.contentName {
  display: -webkit-box;

  padding-bottom: --spaces(4);

  font-weight: bold;
  text-overflow: ellipsis;

  border-bottom: 1px solid rgb(255 255 255 / 10%);

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.addTopicButton {
  display: flex;
  gap: --spaces(2);
  align-items: center;
  justify-content: center;

  width: 100%;
}

.addTopicIcon {
  width: 20px;
}

.listWrapper {
  scrollbar-color: var(--theme-primary) rgb(255 255 255 / 10%);
  scrollbar-width: thin;

  overflow-y: auto;

  width: 100%;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(255 255 255 / 10%);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--theme-primary);
    border-radius: 6px;
  }
}

.topicsList {
  display: flex;
  flex-direction: column;
  gap: --spaces(1);

  margin: 0;
  padding: 0;

  list-style: none;
}

.topicsItem {
  cursor: pointer;

  overflow: hidden;
  display: block;

  width: 100%;
  padding: --spaces(3) --spaces(2);

  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: 8px;

  &.isActive {
    background-color: rgb(255 255 255 / 10%);
  }

  &:hover {
    background-color: rgb(255 255 255 / 10%);
  }
}

.content {
  scrollbar-color: var(--theme-primary) rgb(255 255 255 / 10%);
  scrollbar-width: thin;

  overflow-y: auto;
  display: flex;

  padding: --spaces(3);

  background-color: #2d3136;
  border-radius: 8px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgb(255 255 255 / 10%);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--theme-primary);
    border-radius: 6px;
  }
}

.newTopicContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: --spaces(5);

  width: 100%;
  padding: --spaces(2);
}

.formHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contentTypeBackButton {
  width: 40px;
  height: 40px;
}

.contentTypeBackIcon {
  margin-left: --spaces(2);
  color: var(--theme-primary);
}

.formTitle {
  font-weight: 700;
}

.emptySpace {
  width: 40px;
}

.selectContentTypeContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: --spaces(3);
  align-items: center;
  justify-content: center;
}

.contentTypes {
  display: flex;
  gap: --spaces(3);
  align-items: center;
  justify-content: center;

  width: 100%;
}
