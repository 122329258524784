.infoCard {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 20px;

  color: white;

  background-color: #22262d;
  border-radius: 4px;
}

.aside {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  margin-right: 10px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
}

.icon {
  width: 28px;
  height: 28px;
}

.cardName {
  text-align: start;
}

.total {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  margin-right: 10px;
  padding: 10px;
}

.warningButton {
  cursor: pointer;
  color: #f59e0b;
}
