@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.tutorialsListHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 2rem;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: breakpoints.$breakpoint-sm) {
    transform: translate(10px);
  }

  @media (min-width: breakpoints.$breakpoint-sm) {
    gap: --spaces(4);
  }
}

.dialog {

  /* stylelint-disable selector-class-pattern */

  :global {
    .MuiDialogContent-root {
      overflow: hidden;
    }

    .MuiDialogContentText-root {

      @media (max-width: breakpoints.$breakpoint-sm) {
        width: auto;
      }
    }
  }

  /* stylelint-enable selector-class-pattern */
}

.thumbnailCellImage {
  width: 100px;
  max-height: 50px;
  object-fit: cover;
}
