.chipExpired {
  color: #ff8b8b;
  border-color: #ff8b8b;
}

.chipPaid {
  color: #90ff96;
  border-color: #90ff96;
}

.chipPending {
  color: #f7b888;
  border-color: #f7b888;
}
