@import "src/utils/spacing";

.label {
  cursor: pointer;

  display: flex;
  gap: --spaces(1);
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: --spaces(2) --spaces(4);

  background-color: var(--theme-primary);
  border-radius: 4px;

  &:hover {
    filter: brightness(0.9);
    transition: all 0.2s;
  }
}
