@import "src/utils/spacing";

.container {
  display: flex;
  gap: --spaces(2);

  width: fit-content;
  margin-bottom: --spaces(4);
  padding: --spaces(4);

  background-color: #22262d;
  border-radius: 10px;
}

.text {
  display: flex;
  margin: 0;
}
