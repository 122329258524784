@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.mediasContainer {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: --spaces(4);
  padding: 0;
  padding-left: --spaces(4);

  @media screen and (min-width: breakpoints.$breakpoint-md) {
    flex-direction: row;
  }
}

.mediaContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  @media screen and (min-width: breakpoints.$breakpoint-md) {
    width: 45%;
  }
}

.mediaInputContainer {
  width: 100%;

  :hover {
    color: var(--theme-primary);
    border: 1px dashed var(--theme-primary);
  }
}

.mediaInputContainer:hover :is(svg,img,video) {
  border: 0;
}

.mediaInputContainer > label {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  aspect-ratio: 16/9;
  width: 100%;

  text-align: center;

  border: 1px dashed var(--theme-divider);
  border-radius: 4px;
}

:is(.mediaInputError, .captionsInputError) > label {
  color: #f44336;
  border: 1px dashed #f44336;

  svg,
  img,
  video {
    border: 0;
  }
}

.mediaInputContainer :is(img, video) {
  width: 100%;
}

.mediaInputContainer input {
  display: none;
}

.captionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  margin: --spaces(4) 0;
  padding-left: --spaces(4);
}

.noCaptions {
  font-size: 0.8rem;
}

.captionsList {
  width: 100%;
}

.captionsList li {
  padding-left: --spaces(4);
  border-radius: 4px;

  &:hover {
    background-color: rgb(255 255 255 / 10%);
  }
}

.captionsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.switchContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: --spaces(4);
  padding: 0;
  padding-left: --spaces(4);
}

.backdrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: rgb(0 0 0 / 90%);
}

.loadingContent {
  width: 50%;
  margin-top: --spaces(5);
}

.loadingContent span {
  display: block;
  width: 100%;
  text-align: center;
}
