.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
}

.header {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.accountsSelect {
  width: 100vw;

  @media (min-width: 600px) {
    width: calc(100vw - 72px - 250px);
  }

  @media (min-width: 900px) {
    width: 100%;
    max-width: 350px;
  }

  @media (min-width: 1000px) {
    width: 400px;
  }
}

.main {
  width: 100%;
}

.tabContainer {
  margin-bottom: 20px;
}

.gridContainer {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;

  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 1140px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
