@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: --spaces(2);
  padding: --spaces(3);

  background: #22262d;
  border-radius: 4px;
}

.linkText {
  overflow: hidden;
  flex-shrink: 2;

  max-width: 300px;

  font: inherit;
  color: currentcolor;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: breakpoints.$breakpoint-sm) {
    max-width: 300px;
  }

  @media (min-width: breakpoints.$breakpoint-md) {
    max-width: 500px;
  }

  @media (min-width: breakpoints.$breakpoint-lg) {
    max-width: calc(100% - 40px);
  }
}
