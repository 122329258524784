@import "src/utils/spacing";

.mediaInputContainer {
  width: 100%;

  :hover {
    color: var(--theme-primary);
    border: 1px dashed var(--theme-primary);

    :is(svg, img) {
      border: 0;
    }
  }
}

.thumbnailLabel {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  aspect-ratio: 16/9;
  width: 100%;

  text-align: center;

  border: 1px dashed var(--theme-divider);
  border-radius: 4px;
}

.thumbnailError > .thumbnailLabel {
  color: #f44336;
  border: 1px dashed #f44336;

  :is(svg, img) {
    border: 0;
  }
}

.thumbnailImage {
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.thumbnailInput {
  display: none;
}
