@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.filters {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  margin-top: --spaces(4);
  margin-bottom: --spaces(4);

  & > div {
    min-width: 130px;
  }

  @media (max-width: breakpoints.$breakpoint-md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
