.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: clamp(200px, 100%, 500px);
}

.container p {
  width: 100%;
  margin-bottom: 10px;
  margin-left: 20px;

  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #727272;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.14px;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.inputs input {
  width: 50px;
  height: 45px;

  font-size: 18px;
  font-weight: 800;
  color: #505367;
  text-align: center;

  border: solid 1px #bcc4ce;
  border-radius: 8px;
}

.error span {
  margin-left: 5px;

  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #d92f58;
  text-align: left;
  letter-spacing: 0.14px;
}

.error {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  margin-top: 10px;
}

.errorInput {
  border-color: #d92f58 !important;
}

@media (max-width: 450px) {
  .inputs input {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 340px) {
  .inputs input {
    width: 35px;
    height: 45px;
  }
}
