@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.searchContainer {
  width: 100%;
  margin: --spaces(4) 0 --spaces(3);

  input {
    border-radius: 12px;
  }
}

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(1);
  justify-content: space-between;

  width: 100%;
}

.searchItemWithType {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(4);

  @media (min-width: breakpoints.$breakpoint-md) {
    flex-direction: column;
    width: 69%;
  }
}

.searchItem {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(4);
}

.searchItemSelect {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(4);

  /* stylelint-disable selector-class-pattern */
  :global {
    .MuiSelect-select {
      min-width: 200px;
    }
  }
  /* stylelint-enable selector-class-pattern */

  @media (min-width: breakpoints.$breakpoint-md) {
    flex-direction: column;
    width: 29%;
  }
}
