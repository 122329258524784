@import "src/utils/spacing";

.dialogContent {
  /* stylelint-disable selector-class-pattern */
  :global {
    .MuiDialog-paper {
      max-width: 1000px !important;
    }
  }
  /* stylelint-enabled selector-class-pattern */
}

.changeRow {
  display: flex;
  align-items: center;
  margin-bottom: --spaces(1);

  &:last-child {
    margin-bottom: 0;
  }
}

.fieldName {
  margin-right: --spaces(2);
  font-weight: var(--font-semi-bold);
}

.oldValue {
  margin-right: --spaces(2);
  color: #ff5252;
}

.newValue {
  color: #4caf50;
}

.chevron {
  margin-right: --spaces(2);
}

.divider {
  margin: --spaces(3) 0;
}

.objectValue {
  background: #90caf9;
}

.changeRowObject {
  display: flex;
  flex-direction: column;
  margin-bottom: --spaces(3);

  &:last-child {
    margin-bottom: 0;
  }
}

.newValueObject {
  margin-left: --spaces(4);
  color: #4caf50;
}

.fieldNameObject {
  margin-left: --spaces(4);
}

.emptyField {
  color: #fff9c4;
}
