.actions {
  display: flex;
  justify-content: flex-end;
}

.seeBoletoLink {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  color: inherit;
}
