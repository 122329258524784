@import "src/utils/spacing";

.container {
  display: flex;
  padding: --spaces(3);
  font-size: 15px;
}

.container .title span {
  margin-left: --spaces(3);
  font-weight: normal;
}
