.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;

  img {
    width: 20%;
    margin-bottom: 20px;
  }
}

.paper {
  padding: 30px;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .subtitle {
    margin-bottom: 20px;
  }
  /* stylelint-disable selector-class-pattern */
  & :global .MuiButton-root {
    width: 100%;
    margin-top: 20px;
  }
  /* stylelint-enable selector-class-pattern */
}
