@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  @media screen and (min-width: breakpoints.$breakpoint-md) {
    width: 45%;
  }
}

.mediaInputContainer {
  width: 100%;

  :hover {
    color: var(--theme-primary);
    border-color: var(--theme-primary);

    svg,
    img,
    & > main {
      border: 0;
    }
  }
}

.mediaInputContainer > label {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  aspect-ratio: 16/9;
  width: auto;
  max-width: 100%;
  height: 400px;

  text-align: center;

  border: 1px dashed var(--theme-divider);
  border-radius: 4px;
}

.mediaInputContainer video {
  width: 100%;
  height: auto;
}

.mediaInputContainer input {
  display: none;
}

.mediaInputContainerDisabled {
  opacity: 0.5;
  border: 0;

  :hover {
    cursor: default;
    color: #e9e4e4;
    border-color: var(--theme-divider);
  }
}
