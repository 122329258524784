@import "src/utils/spacing";

.buttonWraper {
  display: flex;
  gap: --spaces(4);
  padding-top: --spaces(2);
}

.editButton {
  padding-top: --spaces(2);
}

.container,
.form {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
  width: 100%;
}

.section {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;
}

.sectionContent {
  display: flex;
  gap: --spaces(3);
  align-items: center;
}

title {
  min-width: 40%;
}
