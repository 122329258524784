@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.tabs {
  button {
    display: flex;
    flex-direction: row-reverse;
    gap: --spaces(2);
    width: 120px;
  }
  /* stylelint-disable selector-class-pattern */
  & :global .Mui-selected {
    color: #ffb204;
  }

  /* stylelint-enable selector-class-pattern */
}
