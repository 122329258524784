@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.containerUrl {
  display: flex;
  flex-direction: column;

  a {
    padding: 1rem;
    color: #c5c5cb;
    text-decoration: none;
  }
}

.tabs {
  button {
    display: flex;
    flex-direction: row-reverse;
    gap: --spaces(2);
    width: 120px;
  }

  /* stylelint-disable selector-class-pattern */
  & :global .Mui-selected {
    color: #ffb204;
  }

  /* stylelint-enable selector-class-pattern */
}

.usersContainer {
  display: flex;
  gap: --spaces(3.8);
}

.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(1);
  justify-content: space-between;

  width: 100%;
  margin-bottom: --spaces(3);
}

.item {
  display: flex;
  width: 100%;
  margin-bottom: --spaces(3);

  div {
    width: 100% !important;
  }

  @media (min-width: breakpoints.$breakpoint-md) {
    width: 49%;
  }
}

.password button {
  position: relative;
  right: -85%;
}

.timezone {
  position: relative;

  button {
    position: absolute;
    right: 0;
  }
}

.errorIconContent {
  width: 20px;
  height: 20px;
  margin-left: --spaces(3);

  color: currentcolor;

  border-radius: 50%;
}
