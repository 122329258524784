@import "src/utils/spacing";

.menuBarButton {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  /* stylelint-disable-next-line csstree/validator */
  margin: 0 --spaces(1);
  padding: 0;

  border-radius: 4px;
}

.menuBarIcon {
  margin: 0;
  padding: 0;

  &:hover {
    background-color: var(--theme-hover-gray);
  }
}

.divider {
  width: 1px;
  /* stylelint-disable-next-line csstree/validator */
  margin: 0 --spaces(3);
  background-color: var(--theme-divider);
}

.isActive {
  background-color: #ffffff80;
  border-radius: 4px;
}
