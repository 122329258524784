@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.errorMessage {
  margin-top: --spaces(0.75);
  margin-left: --spaces(3);

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #f44336;
}

.pickError {

  /* stylelint-disable selector-class-pattern */
  :global {
    .MuiFormLabel-root.Mui-focused {
      color: #f44336;
    }

    .MuiOutlinedInput-root fieldset {
      border-color: #f44336 !important;
    }
  }

  /* stylelint-enable selector-class-pattern */
  label {
    color: #f44336;
  }
}

.datePicker {
  width: 100% !important;

  @media (max-width: breakpoints.$breakpoint-md) {
    flex-direction: column;
    width: 49% !important;
  }
}
