@import "src/utils/spacing";

.accountsListHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: --spaces(2);
}
