@import "src/utils/spacing";

.buttonsContainer {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: --spaces(10);

  & > button {
    width: 50%;
  }
}
