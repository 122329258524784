@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  flex-direction: column;
}

.form {
  padding-bottom: 3rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  gap: --spaces(3);

  width: 100%;
  margin-bottom: --spaces(3);
}

.item {
  width: 100%;
  margin-bottom: --spaces(2);

  @media (min-width: breakpoints.$breakpoint-md) {
    width: 49%;
  }
}

.buttonsContainer {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: --spaces(10);

  & > button {
    width: 50%;
  }
}

.pickError {

  /* stylelint-disable selector-class-pattern */
  :global {
    .MuiFormLabel-root.Mui-focused {
      color: #f44336;
    }

    .MuiOutlinedInput-root fieldset {
      border-color: #f44336 !important;
    }
  }

  /* stylelint-enable selector-class-pattern */
  label {
    color: #f44336;
  }
}

.datePicker {
  width: 100% !important;

  @media (max-width: breakpoints.$breakpoint-md) {
    flex-direction: column;
    width: 49% !important;
  }
}

.errorMessage {
  margin-top: --spaces(0.75);
  margin-left: --spaces(3);

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  color: #f44336;
}
