@use "src/utils/breakpoints" as breakpoints;

@import "src/utils/spacing";

.container {
  display: flex;
  gap: --spaces(10);
  align-items: center;
  justify-content: center;

  width: 100%;
}

.tips {
  display: flex;
  flex-direction: column;
  gap: --spaces(4);
}

.icon {
  display: none;

  @media (min-width: breakpoints.$breakpoint-sm) {
    display: flex;
    min-width: 60px;
  }
}

.divider {
  display: none;

  @media (min-width: breakpoints.$breakpoint-sm) {
    display: flex;
  }
}

.title {
  font-size: 13px;
  font-weight: 600;
  color: var(--theme-text-primary);

  @media (min-width: 1440px) {
    font-size: 15px;
  }
}

.bold {
  font-weight: 700;
  color: var(--theme-text-primary);
}

.content {
  display: flex;
  flex-direction: column;

  font-size: 12px;
  font-weight: 400;
  line-height: 0;
  color: var(--theme-text-primary);

  @media (min-width: 1440px) {
    font-size: 14px;
  }
}

.tip {
  display: flex;
  gap: --spaces(2);
  align-items: center;
}
