@import "src/utils/spacing";

.main {
  cursor: default;

  overflow: initial;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  @media (min-width: 1440px) {
    gap: --spaces(7.5);
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: var(--theme-text-primary);
  text-align: center;
}

.inputWrapper {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: --spaces(5);
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: --spaces(5) --spaces(3);

  background-color: #22262d;
  border-radius: 4px;
}

.dropIndicator {
  display: flex;
  gap: --spaces(3);
  align-items: center;
  justify-content: center;

  padding: --spaces(1) --spaces(3);

  color: var(--theme-primary);

  border: 1px dashed var(--theme-primary);
  border-radius: 4px;
}

.dropText {
  font-size: 0.9rem;
}

.fileTypesText {
  font-size: 0.8rem;
}

.importContent {
  display: flex;
  flex-direction: column;
  gap: --spaces(5);
  align-items: center;
  justify-content: center;

  width: 100%;

  @media (min-width: 960px) {
    width: 70%;
  }

  @media (min-width: 1440px) {
    gap: --spaces(20);
    width: 60%;
  }
}
