@import "src/utils/spacing";

.selectFileContainer {
  display: flex;
  gap: --spaces(3);
  align-items: center;
  margin-bottom: --spaces(8);
}

.attachmentsList {
  display: flex;
  flex-direction: column;
  gap: --spaces(2);

  width: 100%;
  margin: 0;
  padding: 0;
}

.attachmentsItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: --spaces(2);

  border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.attachmentDetails {
  display: flex;
  gap: --spaces(4);
  align-items: center;
  width: 100%;
}

.attachmentIcon {
  width: 40px;
}

.attachmentInput {
  display: none;
}
