@import "src/utils/spacing";

.container {
  display: flex;
  gap: --spaces(1);
  align-items: center;

  height: 35px;
  padding-left: --spaces(1);

  background-color: rgb(255 255 255 / 10%);
  border-radius: 5px;
}

.url {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  gap: --spaces(1);
  align-items: center;
  justify-content: flex-start;

  padding-left: --spaces(1);

  text-decoration: none;
}

.options {
  display: flex;
  gap: --spaces(1);
  margin-right: --spaces(1);
}

.link {
  overflow: hidden;

  width: 140px;
  padding: --spaces(1);

  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  color: var(--theme-primary);
  text-overflow: ellipsis;
  white-space: nowrap;

  background-color: #fff;
  border-radius: 4px;
}

.linkIcon {
  width: 18px;
  height: 18px;
}

.button {
  height: 23px;
  font-size: 10px;
}

.input {
  width: 185px;
  height: 23px;
  padding-left: --spaces(2);

  font-size: 10px;

  border: 0.5px solid #fff;
  border-radius: 5px;
}

.saveContent {
  display: flex;
  flex-direction: row;
  gap: --spaces(1.5);
  align-items: center;

  margin-right: --spaces(1);
  padding: --spaces(1);
}

.copyTooltip {
  min-width: 65px !important;
}
